import React, { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Firebase from 'firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CryptoJS from 'crypto-js';
const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
  appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
  measurementId: "G-5VFLZ6SK9T"
}
Firebase.initializeApp(firebaseConfig);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
const App = () => {
  const [source, setSource] = useState(null);
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const queryParams = new URLSearchParams(window.location.search);
  const routeId = queryParams.get('id')
  const key = 'OnroadApiKey';
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  const dec = CryptoJS.AES.decrypt({
      ciphertext: CryptoJS.enc.Base64.parse(routeId)
    },
    keyutf, {
      iv: iv
    });
  const idString = CryptoJS.enc.Utf8.stringify(dec).split(":")

  const email = idString[0];
  const routeOptimizationId = idString[1];

  const [routeData, setRouteData] = useState(null);

  useEffect(() => {
    if (routeId && email && routeOptimizationId) {
      const onChildAdd = Firebase.database()
        .ref(`/webdeliveries/routeoptimization/${email}/${routeOptimizationId}`)
        .once('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setRouteData(data)
            setSource(data.origin)
          }
        });
      return () => Firebase.database().ref(`/webdeliveries/routeoptimization/${email}/${routeOptimizationId}`).off('value', onChildAdd);
    }
  }, [])

  if (idString && idString.length < 2) {
    return null
  }
  // useEffect(() => {
  //   if (markerRef) {
  //     markerRef.setPosition({
  //       lat: deliveryGuyLocation.lat,
  //       lng: deliveryGuyLocation.lng
  //     })
  //   }
  // }, [markerRef])


  const setMap = ({ map, maps }) => {
    if (!routeData){
      return;
    }
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer({suppressMarkers: true});
    directionsDisplay.setMap(map);

    new maps.Marker({
      map: map,
      label: "S",
      position: {
        lat: parseFloat(routeData.origin.lat),
        lng: parseFloat(routeData.origin.lng)
      },
      draggable: true
    });
    new maps.Marker({
      map: map,
      label: "E",
      position: {
        lat: parseFloat(routeData.destination.lat),
        lng: parseFloat(routeData.destination.lng)
      },
      draggable: true
    });

    const waypointLength = Object.keys(routeData.routeoptimization.route)
    let wayPointsArray = []

    for (let index = 0; index < waypointLength.length; index++) {
      const element = waypointLength[index];
      wayPointsArray.push({
        location: new maps.LatLng(parseFloat(routeData.routeoptimization.route[element].lat), parseFloat(routeData.routeoptimization.route[element].lng))
      })
      const markerRef = new maps.Marker({
        map: map,
        label: element,
        draggable: true
      });
      markerRef.setPosition(
        {
          lat: parseFloat(routeData.routeoptimization.route[element].lat),
          lng: parseFloat(routeData.routeoptimization.route[element].lng)
        }
      )
      const contentString = "<p><b>Address :</b>" + 
                            `${routeData.listofdeliveries[parseInt(element) - 1]?.address}` + 
                            "<p><b>Location :</b> <br/>Lat:" +
                            `${routeData.listofdeliveries[parseInt(element)-1]?.lat}` +
                            "Lng:" +
                            `${routeData.listofdeliveries[parseInt(element)]?.lng}`

      const infowindow = new maps.InfoWindow({
        content: contentString,
        maxWidth: 200
      });
      maps.event.addListener(markerRef, 'click', function () {
        infowindow.open(map, markerRef);
        // setTimeout(function () {
        //   infowindow.close();
        // }, 5000);
      });
      
    }

    directionsService.route(
      {
        destination: {
          lat: parseFloat(routeData.destination.lat),
          lng: parseFloat(routeData.destination.lng)
        },
        origin: {
          lat: parseFloat(routeData.origin.lat),
          lng: parseFloat(routeData.origin.lng)
        },
        travelMode: 'DRIVING',
        waypoints: wayPointsArray
      }, (result, status) => {
        if (status == 'OK') {
          directionsDisplay.setDirections(result);
        }
      }
    );
  };
 
  if (!routeData) {
    return (
      <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', backgroundColor: "green" }} >
        <h>Please Wait ... </h>
      </div>
    );
  } else {
    return (
      <div style={{ height: '100vh', width: '100%', backgroundColor: 'yellow' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk' }}
          defaultCenter={{
            lat: source?.lat,
            lng: source?.lng
          }}
          defaultZoom={15}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={setMap}
        >
        </GoogleMapReact>
      </div>
    );
  }
}
export default App;